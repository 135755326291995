import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogTemplate.tsx";
import SEO from "Components/core/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Aspects Of Games" description="I muse about a way to break games down to genres" mdxType="SEO" />
    <p>{`While pondering on game ideas I started thinking about game genres, this then quickly escalated into me breaking down, and trying to understand them. Which was more interesting than you would expect; you see I feel genres are meant to make it easier to predict what a games experience will be, however; when you look at genres it’s apparent this isn’t really how they’re used, for instance; look at the Indie Category on Steam, the games in this genre have almost nothing in common besides publishing model, which in my view doesn’t really affect the player’s experience. So I want to explore this topic further and propose a solution, but first we must understand genres.`}</p>
    <h2>{`What are Game Genres?`}</h2>
    <p>{`I would define a game genre as key descriptive words that describes the experience of a game. In essence genres are meant to help players understand what sort of gameplay a game provides, as well as allowing them to quickly connect previous experiences from similar games. This second part is important for smaller games, as it could allow players to become more comfortable when buying unknown games, but, this would only work if genres can be trusted.`}</p>
    <p>{`However, as game genres stand, they only give a vague silhouette of what a game is, this is seen in typical genres like “FPS” which only describes the visual perspective, and the main form of interaction in the game, but doesn’t give any sense of how that interaction is used. Then there are genres like “Action” which only indicates that the gameplay is lively, and this sort of vagueness is common across genres, causing their use to fall off, even Steam has given up on any sort of curated genre categorisation, and has left it up to the community to give games Tags.`}</p>
    <p>{`So with that in mind I want to spend the rest of this post deconstructing game genres and rebuilding them into something that I have put together by analysing the topic in my own way.`}</p>
    <p>{`Deconstructing Genres
When something is just functioning most people will accept it for what it is, but sometimes you need to take it apart and figure out how it really works, because just functioning isn’t a goal, it’s a cop out. With genres I decided to take a step back and look at what genres say about the games, breaking them down to their raw essence and stripping away what makes them so generic. In doing this I was able to break game experiences down into 6 aspects, those being:`}</p>
    <ul>
      <li parentName="ul">{`Core Gameplay`}</li>
      <li parentName="ul">{`Arching Gameplay`}</li>
      <li parentName="ul">{`Visual Perspective`}</li>
      <li parentName="ul">{`Time Format`}</li>
      <li parentName="ul">{`Emotional Feel`}</li>
      <li parentName="ul">{`Player Interaction`}</li>
      <li parentName="ul"></li>
    </ul>
    <p>{`With each of these aspects I try to break down the key parts of a game and use them to inform the players about the experience they should expect from a game. Next I will go into detail about what each aspect means and further on I will provide examples of the aspects in use.`}</p>
    <h2>{`Core Gameplay`}</h2>
    <p>{`Core gameplay is the dominant active gameplay mechanic that a player will encounter in a game. Core gameplay mechanics are mostly ways players interact directly with a game e.g., Shooting, Platforming, Puzzling, Driving, etc.`}</p>
    <h2>{`Arching Gameplay`}</h2>
    <p>{`Arching gameplay mechanics surround the core gameplay, but unlike core gameplay they aren’t directly interacted with. These mechanics could take the form as different progression mechanics`}{`*`}{` or goals the designer wants a player to achieve.`}</p>
    <p>{`*`}{`Free to play can be relevant to arching gameplay mechanic, but only when it is integrated into progression mechanics.`}</p>
    <h2>{`Visual Perspective`}</h2>
    <p>{`Visual perspective is essentially the type of view the game will primarily be played in as well, as the graphical demotions associated with the perspective.`}</p>
    <h2>{`Time Format`}</h2>
    <p>{`Time format is the flow of game time and activity time, so as an example, you could have a real time game that had cool down based combat mechanics. The game is played in real time but the combat is based on timers/cooldowns.`}</p>
    <h2>{`Emotional Feel`}</h2>
    <p>{`Emotional feel is about the type of emotions the game invokes in a player. A basic example of this would be horror, but it is important to think beyond simple reaction based emotions. Challenging, relaxed, casual and intense would all fall under the heading of emotional feel.`}</p>
    <h2>{`Player Interaction`}</h2>
    <p>{`Player interaction is about how the player interactions with the game and other players, and how much interaction is expected from them. E.g. multiplayer, single player, co-op, passive single player`}{`*`}{`.`}</p>
    <p>{`*`}{`Passive play would be games that don’t require a lot of attention, check out A Dark Room for an example.`}</p>
    <h2>{`Using Game Aspects`}</h2>
    <p>{`Using Game Aspects a games experience is broken into 6 different aspects, below I have created 5 examples of these aspects being used to hopefully explain each games experience.`}</p>
    <h3>{`Kerbal Space Program`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABkeZU4mFf/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDABAR/9oACAEBAAEFApTTy0FClBmz8//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAETH/2gAIAQIBAT8BxX//xAAbEAEAAgIDAAAAAAAAAAAAAAABAAIQIRFBof/aAAgBAQAGPwIXsm7Bx7NWIZ//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhQRAxcf/aAAgBAQABPyEuZWG9MJtGixkHBpHt9n//2gAMAwEAAgADAAAAEH/v/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxBOorT/xAAWEQEBAQAAAAAAAAAAAAAAAAARAFH/2gAIAQIBAT8QFDL/xAAfEAACAQMFAQAAAAAAAAAAAAAAATERUYEhYXGhscH/2gAIAQEAAT8Qp8qBpsNDJgtWe5LI3CE1oSw8Jcfh2h//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Kerbal Space Program",
          "title": "Kerbal Space Program",
          "src": "/static/3c698f98693e51e616e86032510c74db/e41a8/aspects-kerbal-space-program.jpg",
          "srcSet": ["/static/3c698f98693e51e616e86032510c74db/09b79/aspects-kerbal-space-program.jpg 240w", "/static/3c698f98693e51e616e86032510c74db/e41a8/aspects-kerbal-space-program.jpg 460w"],
          "sizes": "(max-width: 460px) 100vw, 460px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Core Gameplay
Rocket Building, Space Exploration`}</p>
    <p>{`Arching Gameplay
Technology/Exploration Progression`}</p>
    <p>{`Visual Perspective
Third Person`}</p>
    <p>{`Time Format
Real Time, Time Acceleration`}</p>
    <p>{`Emotional Feel
Accomplishment, Awe`}</p>
    <p>{`Player Interaction
Single Player`}</p>
    <h2>{`Anno 2070`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDBP/aAAwDAQACEAMQAAABNia2as0o/8QAGRABAQEAAwAAAAAAAAAAAAAAAgEAETJB/9oACAEBAAEFAiZqgcrOTl18/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAITIWH/2gAIAQMBAT8BWyTD/8QAFhEAAwAAAAAAAAAAAAAAAAAAAQIQ/9oACAECAQE/AWM//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwISMin/xAAcEAACAgIDAAAAAAAAAAAAAAAAARExIbFBcfH/2gAIAQEAAT8hR9EjInlD0ljBQ2dCp0f/2gAMAwEAAgADAAAAEF8//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EGJ0sT//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAECAQE/EGLHt//EAB0QAQAABgMAAAAAAAAAAAAAAAEAEBEhUWExcfD/2gAIAQEAAT8QOLDlFiGcZRd5NQKEKooHcny7SL//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Anno 2070",
          "title": "Anno 2070",
          "src": "/static/ddfd60cf4cfed273b1c22548c7d2f6d6/e41a8/aspects-anno-2070.jpg",
          "srcSet": ["/static/ddfd60cf4cfed273b1c22548c7d2f6d6/09b79/aspects-anno-2070.jpg 240w", "/static/ddfd60cf4cfed273b1c22548c7d2f6d6/e41a8/aspects-anno-2070.jpg 460w"],
          "sizes": "(max-width: 460px) 100vw, 460px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Core Gameplay
Trade Management, City Building`}</p>
    <p>{`Arching Gameplay
Expansion, Tech Progression`}</p>
    <p>{`Visual Perspective
3D Isometric`}</p>
    <p>{`Time Format
Real Time`}</p>
    <p>{`Emotional Feel
Calm, Casual`}</p>
    <p>{`Player Interaction
Single Player, Multiplayer`}</p>
    <h2>{`The Binding of Isaac: Rebirth`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeI7JeGEi//EABoQAAEFAQAAAAAAAAAAAAAAAAABAgMREjH/2gAIAQEAAQUCtpHi5ETY3qn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAABAwUAAAAAAAAAAAAAAAAAASAhIjFRgaH/2gAIAQEABj8CSnpjZFmf/8QAHBABAAIBBQAAAAAAAAAAAAAAAQARIRBBUWFx/9oACAEBAAE/IQTI8wkqHMzFxfpqbfJ//9oADAMBAAIAAwAAABDvD//EABURAQEAAAAAAAAAAAAAAAAAAAEA/9oACAEDAQE/EBkv/8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQIBAT8QYb//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMUFRYfD/2gAIAQEAAT8QOF4ZtbfWMRgSEFejuKgsp08kNzZN/rbP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The Binding of Isaac: Rebirth",
          "title": "The Binding of Isaac: Rebirth",
          "src": "/static/e6ae43d2281643e7404df6720978e0d9/e41a8/aspects-the-binding-of-isaac-rebirth.jpg",
          "srcSet": ["/static/e6ae43d2281643e7404df6720978e0d9/09b79/aspects-the-binding-of-isaac-rebirth.jpg 240w", "/static/e6ae43d2281643e7404df6720978e0d9/e41a8/aspects-the-binding-of-isaac-rebirth.jpg 460w"],
          "sizes": "(max-width: 460px) 100vw, 460px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Core Gameplay
Shooting, Power Ups`}</p>
    <p>{`Arching Gameplay
Rooms of Enemies, Randomly Generated, Endless Level Variety, Roguelike`}</p>
    <p>{`Visual Perspective
Top Down`}</p>
    <p>{`Time Format
Real Time`}</p>
    <p>{`Emotional Feel
Intense, Dark`}</p>
    <p>{`Player Interaction
Single Player, Minor Local Co-Op`}</p>
    <h2>{`DotA 2`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABw2ptCyCv/8QAGBAAAgMAAAAAAAAAAAAAAAAAARAAAhH/2gAIAQEAAQUCAlsQf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABICH/2gAIAQEABj8CMU//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQsf/aAAgBAQABPyGxacB5Wf/aAAwDAQACAAMAAAAQr8//xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8QGEz/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAGxABAAICAwAAAAAAAAAAAAAAAQAxESFBcYH/2gAIAQEAAT8QsBkLhPS3ukBmU9Jw7Iz/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "DotA 2",
          "title": "DotA 2",
          "src": "/static/89c7b9d684fad4164a941046769af2e5/e41a8/aspects-dota-2.jpg",
          "srcSet": ["/static/89c7b9d684fad4164a941046769af2e5/09b79/aspects-dota-2.jpg 240w", "/static/89c7b9d684fad4164a941046769af2e5/e41a8/aspects-dota-2.jpg 460w"],
          "sizes": "(max-width: 460px) 100vw, 460px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Core Gameplay
Strategy, Spell Combat, Team Work`}</p>
    <p>{`Arching Gameplay
Light RPG Elements, Competitive Progression, Skills Development`}</p>
    <p>{`Visual Perspective
3D Isometric`}</p>
    <p>{`Time Format
Real Time, Cooldown Mechanics`}</p>
    <p>{`Emotional Feel
Intense, Engaging, Accomplishment, Frustration`}</p>
    <p>{`Player Interaction
5V5 Team Based Multiplayer, Minor Single Player Training Mode.`}</p>
    <h2>{`The Swindle`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAYiSa2OP/8QAGRABAAIDAAAAAAAAAAAAAAAAAQACEiEy/9oACAEBAAEFAmy0z0wj1P/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAABBQEAAAAAAAAAAAAAAAAAAQISICFx/9oACAEBAAY/AsaRE5T/xAAbEAEAAQUBAAAAAAAAAAAAAAABABEhMUFxUf/aAAgBAQABPyEgB2sG26HsIFN9prMnY5Z//9oADAMBAAIAAwAAABAzD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QH//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EIj/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMVFhcaHw/9oACAEBAAE/EKkxBsI2WZWGTqAaVsebZv5E+3qe9P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The Swindle",
          "title": "The Swindle",
          "src": "/static/17600e9e2ae2bbefe2461f9e3e698c7c/e41a8/aspects-the-swindle.jpg",
          "srcSet": ["/static/17600e9e2ae2bbefe2461f9e3e698c7c/09b79/aspects-the-swindle.jpg 240w", "/static/17600e9e2ae2bbefe2461f9e3e698c7c/e41a8/aspects-the-swindle.jpg 460w"],
          "sizes": "(max-width: 460px) 100vw, 460px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Core Gameplay
Stealth, Platforming`}</p>
    <p>{`Arching Gameplay
Ability Unlocks, Roguelike`}</p>
    <p>{`Visual Perspective
Side Scrolling`}</p>
    <p>{`Time Format
Real Time`}</p>
    <p>{`Emotional Feel
Accomplishment, Tension, Frustration`}</p>
    <p>{`Player Interaction
Single Player`}</p>
    <p>{`Breakdown of Aspects
Below I have created a simple list of possible aspects for each category. This list is not complete, and if you think one of the aspects is in the wrong category, I challenge you to question and analyse it.`}</p>
    <p>{`Core Gameplay
Shooter, Platformer, Puzzle, Stealth, Building, Combat, Brawling.`}</p>
    <p>{`Arching Gameplay
Exploration, Progression, Random Generation, Unlocks, Roguelike, Metroidvania, Advancement, Challenges, Story Driven.`}</p>
    <p>{`Visual Perspective
Top Down, Ant Farm, Third Person, First Person, Isometric, Freecam, 3D, 2D, 21/2D.`}</p>
    <p>{`Time Format
Real Time, Turn Based, Resource based, Cooldown/timer based.`}</p>
    <p>{`Emotional Feel
Horror, Accomplishment, Tension, Suspense, Engaging, Intense, Hardcore, Casual.`}</p>
    <p>{`Player Interaction
Single, Multi, Passive Single, Passive Multi, Co-Op.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      